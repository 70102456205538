// Images file for 'trusteddownpaymentsurvey.com'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import Banner from './assets/banner.jpg';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import Unselected from '../../../../src/images/unselected-square.png';
import Selected from '../../../../src/images/selected-square.png';
import SliderArrows from '../../../../src/images/slider-arrows.png';
// credit images
import Excellent from './assets/credit/excellent.png';
import Good from './assets/credit/good.png';
import Average from './assets/credit/average.png';
import Fair from './assets/credit/fair.png';
import Poor from './assets/credit/poor.png';

export default {
  Favicon,
  Banner,
  Dropdown,
  Unselected,
  Selected,
  SliderArrows,
  Excellent,
  Good,
  Average,
  Fair,
  Poor,
};
